<template>
  <div class="report">
    <v-container>
      <div class="main-container">
        <v-card
          rounded="lg"
          class="pa-9"
          :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
        >
          <h2 class="mb-4">{{ 'dash.report.recap.title' | text }}</h2>
          <filter-recap />
        </v-card>
         <v-card
          rounded="lg"
          class="pa-9 mt-4"
          :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
        >
          <v-alert
            dismissible
            type="error"
            v-model="isShowAlertTable"
          >{{ alertMsgTable }}</v-alert>
          <h2 class="mb-4">{{ 'dash.report.dikti.title' | text }}</h2>
          <filter-student @setFilterData="setFilterData" source="report" />
          <v-divider class="my-8"></v-divider>
          <form-tables
            ref="tableData" 
            :filterData="filterData" 
            :isColoumAction="false" 
            @handleAlertTables="handleAlertTables()" 
          />
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import FilterRecap from '@/apps/dashboard/components/filters/filterRecap'
import FilterStudent from '@/apps/dashboard/components/filters/filterStudent'
import FormTables from '../components/tables/formTables'
import {
  LABEL
} from '@/constants/pages'
  export default {
    name: 'Report',
    components: {
      FilterRecap,
      FilterStudent,
      FormTables,
    },
    data () {
      return {
        page: 1,
        isShowAlertTable: false,
        alertMsgTable: '',
        isShowAlertLabel: false,
        alertMsgLabel: '',
        isShowAlertCategory: false,
        alertMsgCategory: '',
        filterData: {
          name: '',
          status: 'accepted',
          program_study: '',
          season: '',
          period: ''
        },
        filterLabel: {
          name: '',
          category: 'Semua',
          city: '',
          province: '',
        },
        isShowTables: false
      }
    },
    computed: {
      
    },
    methods: {
      setFilterData (params) {
        this.filterData.name = params.name
        // this.filterData.status = params.status === 'Semua' ? '' : params.status
        this.filterData.program_study = params.program_study === 'Semua' ? '' : params.program_study
        this.filterData.season = params.season === 0 ? '' : params.season
        this.filterData.period = Date.parse(new Date(params.period))/1000 ? Date.parse(new Date(params.period))/1000 : 0
        this.$refs.tableData && this.$refs.tableData.setFilterData()
      },
      handleAlertTables (isShowAlertTable, msg) {
        this.isShowAlertTable = isShowAlertTable
        this.alertMsgTable = msg
      },
    }
  }
</script>

<style lang="scss" scoped>
.report {

}
</style>