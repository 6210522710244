var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter-student"},[_c('v-alert',{staticClass:"pa-4",attrs:{"dense":"","text":"","border":"left","prominent":""}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('h4',{staticClass:"mt-2"},[_vm._v("Keterangan Status")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.expandStatus()}}},[_c('v-icon',[_vm._v("mdi-menu")])],1)],1),(_vm.isExpand)?_c('div',{staticClass:"my-4"},_vm._l((_vm.status),function(itm){return _c('div',{key:itm.status,staticClass:"status-info"},[_c('v-row',[_c('v-col',{attrs:{"cols":"3","sm":"3","md":"3","lg":"3"}},[_vm._v(" "+_vm._s(itm.status_name)+" ")]),_c('v-col',{attrs:{"cols":"9","sm":"9","md":"9","lg":"9"}},[_vm._v(" : "+_vm._s(itm.description ? itm.description : '-')+" ")])],1)],1)}),0):_vm._e()]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{attrs:{"id":"filter-student-list"},on:{"submit":function($event){$event.preventDefault();return _vm.checkStudentList.apply(null, arguments)}}},[_c('v-row',{staticClass:"general-form",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"general-form__col",attrs:{"cols":"12","sm":"12","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Nama Mahasiswa","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"title-14 mb-2"},[_vm._v("Nama Mahasiswa")]),_c('v-text-field',{attrs:{"placeholder":"Nama Mahasiswa","error-messages":errors,"clearable":"","type":"text","outlined":"","dense":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}),(_vm.isFormList)?_c('div',[_c('p',{staticClass:"title-14 mb-2"},[_vm._v("Status")]),_c('v-select',{attrs:{"items":_vm.statusData,"dense":"","outlined":""},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}})],1):_vm._e(),_c('v-menu',{ref:"menu",attrs:{"value":_vm.menu,"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"Periode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"title-14 mb-2"},[_vm._v("Periode")]),_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.computedYearFormattedMomentjs(_vm.periode),"placeholder":"Periode","error-messages":errors,"prepend-icon":"mdi-calendar","readonly":"","clearable":"","outlined":"","dense":""},on:{"click:clear":function($event){_vm.periode = ''}}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true)},[_c('v-date-picker',{attrs:{"type":"month"},on:{"change":function($event){_vm.menu = false}},model:{value:(_vm.periode),callback:function ($$v) {_vm.periode=$$v},expression:"periode"}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.periode)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{staticClass:"general-form__col",attrs:{"cols":"12","sm":"12","md":"6","lg":"6"}},[_c('div',[_c('p',{staticClass:"title-14 mb-2"},[_vm._v("Program Studi")]),_c('v-select',{attrs:{"items":_vm.programStudies,"dense":"","outlined":""},model:{value:(_vm.programStudy),callback:function ($$v) {_vm.programStudy=$$v},expression:"programStudy"}})],1),_c('div',[_c('p',{staticClass:"title-14 mb-2"},[_vm._v("Gelombang")]),_c('v-select',{attrs:{"items":_vm.universityIntakes,"dense":"","outlined":""},model:{value:(_vm.universityIntake),callback:function ($$v) {_vm.universityIntake=$$v},expression:"universityIntake"}})],1)])],1),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"primary","min-width":160,"type":"submit","form":"filter-student-list","large":"","disabled":_vm.isDisabled || invalid}},[_vm._v(" TEMUKAN ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }