<template>
  <div>
    <v-alert
      dismissible
      type="error"
      class="mt-4"
      v-model="isShowAlert"
    >{{ alertMsg }}</v-alert>
    <validation-observer v-slot="{ invalid }">
      <v-form id="filter-recap" @submit.prevent="checkRecap">
        <v-row no-gutters class="general-form">
          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="6"
            class="general-form__col"
          >
            <v-menu
              ref="menu"
              :value="menu"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <validation-provider
                  v-slot="{ errors }"
                  name="Periode"
                  rules="required"
                >
                  <p class="title-14 mb-2">Periode</p>
                  <v-text-field
                    :value="computedYearFormattedMomentjs(periode)"
                    placeholder="2021"
                    :error-messages="errors"
                    prepend-icon="mdi-calendar"
                    readonly
                    clearable
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="periode = ''"
                  ></v-text-field>
                </validation-provider>
              </template>
              <v-date-picker
                v-model="periode"
                type="month"
                @change="menu = false"
              >
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(periode)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <div class="d-flex justify-end">
          <v-btn 
            color="primary"
            :min-width="160"
            type="submit"
            form="filter-recap"
            large
            :disabled="invalid || !periode"
          >
            UNDUH
          </v-btn>
        </div>
      </v-form>
    </validation-observer>
    <dialog-info ref="dialogInfoSuccess" :info="infoSuccess"/>
  </div>
</template>

<script>
  // Libs
  import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
  import { required, email } from 'vee-validate/dist/rules'
  import { get } from 'dot-prop'
  import moment from 'moment'

  // Utils
  import loadscreen from '@/utils/common/loadScreen'
  import request from '@/utils/request'
  
  // Constants
  import {
    API_DASH_RECAP
  } from '@/constants/apis'

  // Components
  import DialogInfo from '@/components/common/DialogInfo'

  export default {
    name: 'FilterRecap',
    components: {
      ValidationObserver,
      ValidationProvider,
      DialogInfo
    },
    data () {
      return {
        menu: false,
        periode: '',
        isShowAlert: false,
        alertMsg: '',
        infoSuccess: {
          title: 'dialog.success',
          desc: 'dialog.success.generate',
          btn: 'dialog.success.btn'
        }
      }
    },
    computed: {
      isDisabled () {
        return this.isError
      },
    },
    mounted () {
      extend('required', required)
    },
    methods: {
      async checkRecap () {
        this.$emit('handleAlert', false, '')
        loadscreen.show()
        const params = {
          academic_year: Date.parse(new Date(this.periode))/1000 > 0 ? Date.parse(new Date(this.periode))/1000 : 0,
        }
        request(API_DASH_RECAP, params).then(res => {
          if (res.success && res.link_file) {
            window.open(res.link_file, '_blank')
            this.$refs.dialogInfoSuccess && this.$refs.dialogInfoSuccess.show()
          } else {
            this.alertMsg = get(res, 'resultInfo.resultMsg', 'Error System')
            this.isShowAlert = true
          }
        })
        loadscreen.hide()
      },
      computedYearFormattedMomentjs (date) {
        return date ? moment(new Date(date)).locale('id').format('YYYY') : ''
      },
    },
  }
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
</style>
