<template>
  <div class="filter-student">
    <v-alert
      dense
      text
      border="left"
      prominent
      class="pa-4"
    >
      <div class="d-flex justify-space-between">
        <h4 class="mt-2">Keterangan Status</h4>
        <v-btn
          text
          color="primary"
          @click="expandStatus()"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </div>
      <div v-if="isExpand" class="my-4">
        <div v-for="itm in status" :key="itm.status" class="status-info">
          <v-row>
            <v-col
              cols="3"
              sm="3"
              md="3"
              lg="3"
            >
              {{itm.status_name}}
            </v-col>
            <v-col
              cols="9"
              sm="9"
              md="9"
              lg="9"
            >
              : {{itm.description ? itm.description : '-'}}
            </v-col>
          </v-row>
        </div>
      </div>
    </v-alert>
    <validation-observer v-slot="{ invalid }">
      <v-form id="filter-student-list" @submit.prevent="checkStudentList">
        <v-row no-gutters class="general-form">
          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="6"
            class="general-form__col"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Nama Mahasiswa"
              rules=""
            >
              <p class="title-14 mb-2">Nama Mahasiswa</p>
              <v-text-field
                placeholder="Nama Mahasiswa"
                v-model="name"
                :error-messages="errors"
                clearable
                type="text"
                outlined
                dense
              ></v-text-field>
            </validation-provider>
            <div v-if="isFormList">
              <p class="title-14 mb-2">Status</p>
              <v-select
                v-model="state"
                :items="statusData"
                dense
                outlined
              ></v-select>
            </div>
            <v-menu
              ref="menu"
              :value="menu"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <validation-provider
                  v-slot="{ errors }"
                  name="Periode"
                >
                  <p class="title-14 mb-2">Periode</p>
                  <v-text-field
                    :value="computedYearFormattedMomentjs(periode)"
                    placeholder="Periode"
                    :error-messages="errors"
                    prepend-icon="mdi-calendar"
                    readonly
                    clearable
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="periode = ''"
                  ></v-text-field>
                </validation-provider>
              </template>
              <v-date-picker
                v-model="periode"
                type="month"
                @change="menu = false"
              >
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(periode)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="6"
            class="general-form__col"
          >
            <div>
              <p class="title-14 mb-2">Program Studi</p>
              <v-select
                v-model="programStudy"
                :items="programStudies"
                dense
                outlined
              ></v-select>
            </div>
            <div>
              <p class="title-14 mb-2">Gelombang</p>
              <v-select
                v-model="universityIntake"
                :items="universityIntakes"
                dense
                outlined
              ></v-select>
            </div>
          </v-col>
        </v-row>
        <div class="d-flex justify-end">
          <v-btn 
            color="primary"
            :min-width="160"
            type="submit"
            form="filter-student-list"
            large
            :disabled="isDisabled || invalid"
          >
            TEMUKAN
          </v-btn>
        </div>
      </v-form>
    </validation-observer>
  </div>
</template>

<script>
  // Libs
  import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
  import { get } from 'dot-prop'
  import moment from 'moment'

  // Utils
  import loadscreen from '@/utils/common/loadScreen'
  import request from '@/utils/request'
  
  // Constants
  import {
    API_DASH_GET_STATUS
  } from '@/constants/apis'

  export default {
    name: 'FilterStudent',
    components: {
      ValidationObserver,
      ValidationProvider,
    },
    data () {
      return {
        name: '',
        programStudy: 'Semua',
        universityIntake: 'Semua',
        state: 'Semua',
        periode: '',
        menu: false,
        status: [{
          status: 'Semua',
          status_name: 'Semua'
        }],
        isExpand: false
      }
    },
    props: {
      source: {
        type: String,
        default: 'formList'
      }
    },
    computed: {
      isDisabled () {
        return this.isError
      },
      isFormList () {
        return this.source === 'formList'
      },
      programStudiesData () {
        return [{
          value: 'Semua',
          field: 'Semua'
        }, {
          value: 'Program Studi Sarjana Teologi (S.Th.)',
          field: 'sth'
        }, 
        // {
        //   value: 'Program Studi Magister Divinitas (M.Div.)',
        //   field: 'mdiv'
        // }, 
        {
          value: 'Program Studi Magister Ministri (M.Min.)',
          field: 'mmin'
        }, {
          value: 'Program Studi Magister Teologi S1 Teologi (M.Th.)',
          field: 'mth'
        },  {
          value: 'Program Studi Magister Teologi S1 Umum (M.Th.)',
          field: 'mthu'
        }, {
          value: 'Program Sertifikat Teologi',
          field: 'sert'
        }]
      },
      programStudies () {
        return this.programStudiesData.map(itm => itm.value)
      },
      statusData () {
        return this.status.map(itm => itm.status_name)
      },
      dashAcademicYear () {
        return get(this.$store.state, 'dashboard.dashAcademicYear', [])
      },
      universityIntakes() {
        const academicYear = this.dashAcademicYear
        const list = ['Semua']
        academicYear.map(itm => {
          list.push(itm.title)
        })
        return list
      },
    },
    async created () {
      await request(API_DASH_GET_STATUS, {}, {methods: 'GET'}).then(res => {
        if (res.success) {
          res.list.map(itm => {
            this.status.push(itm)
          })
        }
      })
      const res = await this.$store.dispatch('dashboard/getDashAcademicYear')
      if (res.success) {
        this.universityIntake = this.universityIntakes[0]
      }
    },
    methods: {
      computedYearFormattedMomentjs (date) {
        return date ? moment(new Date(date)).locale('id').format('YYYY') : ''
      },
      async checkStudentList () {
        this.$emit('handleAlert', false, '')
        loadscreen.show()
        const selectedAcademic = this.dashAcademicYear.filter(itm => itm.title === this.universityIntake)
        const params = {
          name: this.name,
          season: get(selectedAcademic[0], 'season', ''),
          period: this.periode
        }
        this.status.map(itm => {
          if (itm.status_name === this.state) {
            params.status = itm.status
          }
        })
        this.programStudiesData.map(itm => {
          if (itm.value === this.programStudy) {
            params.program_study = itm.field
          }
        })
        this.$emit('setFilterData', params)
      },
      expandStatus () {
        this.isExpand = !this.isExpand
      }
    },
  }
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
.filter-student {
  .status-info {
    color: black;
    font-size: 14px;
    margin: 0;
  }
}
</style>
