<template>
  <div class="form-tables">
    <v-data-table
      v-model="selected"
      :headers="headersData"
      :items="referenceList"
      :loading="loading"
      :items-per-page="10"
      :footer-props="{'items-per-page-options':[10, 25, 50, 100]}"
      :show-select="!isColoumAction"
      class="elevation-2 mt-4"
      @update:options="updateOpt"
    >
      <template v-if="isColoumAction" v-slot:[`item.actions`]="{ item }">
        <v-btn
          small
          color='primary'
          @click="openDetail(item)"
        >
          LIHAT DETAIL
        </v-btn>
      </template>
    </v-data-table>
    <div class="d-flex justify-end pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="7"
        @input="changeListPage"
      ></v-pagination>
    </div>
    <div v-if="!isColoumAction" class="d-flex justify-end mt-4">
      <v-btn 
        color="primary"
        :min-width="160"
        type="button"
        outlined
        large
        :disabled="selected.length === 0"
        class="mr-2"
        @click="selected = []"
      >
        BATALKAN PILIH
      </v-btn>
      <v-btn 
        color="primary"
        :min-width="160"
        type="button"
        large
        :disabled="selected.length === 0"
        @click="downloadData"
      >
        <v-badge
          v-if="selected.length > 0"
          color="green"
          :content="selected.length"
        >
           UNDUH
        </v-badge>
        <div v-else>UNDUH</div>
      </v-btn>
    </div>
    <dialog-info ref="dialogInfoSuccessDonwload" :info="infoSuccess"/>
  </div>
</template>

<script>
import {get} from 'dot-prop'
import moment from 'moment'
import JSZip from 'jszip'
import JSZipUtils from 'jszip-utils'
import saveAs from 'save-as'
import { text } from '@/apps/web/constants/text'
import loadscreen from '@/utils/common/loadScreen'
import DialogInfo from '@/components/common/DialogInfo'
import {
  DETAIL
} from '@/constants/pages'
import {
  API_DASH_FORM_LIST
} from '@/constants/apis'
import request from '@/utils/request'
  export default {
    name: 'FormList',
    props: {
      filterData: {
        type: Object,
        default: () => {}
      },
      isColoumAction: {
        type: Boolean,
        default: true
      }
    },
    components: {
      DialogInfo
    },
    data () {
      return {
        isShowAlert: false,
        alertMsg: '',
        isShowAlertTable: false,
        alertMsgTable: '',
        headers: [
          {
            text: 'Nama Mahasiswa',
            align: 'start',
            value: 'name',
            class: 'black--text subtitle-2'
          },
          { text: 'Gelombang', value: 'season', class: 'black--text subtitle-2' },
          { text: 'Periode', value: 'academic_year', class: 'black--text subtitle-2' },
          { text: 'Program Studi', value: 'program_study', class: 'black--text subtitle-2' },
          { text: 'Status', value: 'status_name', class: 'black--text subtitle-2' },
          { text: 'Detail', value: 'actions', sortable: false, class: 'black--text subtitle-2' }
        ],
        headersWithoutAction: [
          {
            text: 'Nama Mahasiswa',
            align: 'start',
            value: 'name',
            class: 'black--text subtitle-2'
          },
          { text: 'Gelombang', value: 'season', class: 'black--text subtitle-2' },
          { text: 'Periode', value: 'academic_year', class: 'black--text subtitle-2' },
          { text: 'Program Studi', value: 'program_study', class: 'black--text subtitle-2' },
          { text: 'Status', value: 'status_name', class: 'black--text subtitle-2' },
        ],
        referenceList: [],
        totalList: 0,
        loading: false,
        limit: 10,
        options: {},
        page: 1,
        pageCount: 1,
        selected: [],
        infoSuccess: {
          title: 'dialog.success',
          desc: 'dialog.success.generate',
          btn: 'dialog.success.btn'
        }
      }
    },
    computed: {
      headersData () {
        return this.isColoumAction ? this.headers : this.headersWithoutAction
      }
    },
    methods: {
      updateOpt (e) {
        if (e.itemsPerPage !== this.limit) {
          this.limit = e.itemsPerPage
          this.setFilterData()
        }
      },
      setFilterData () {
        this.page = 1
        this.getDataFromApi()
      },
      getDataFromApi () {
        this.loading = true
        this.isShowAlertTable = false
        const params = {}
        params.page = this.page
        params.limit = this.limit
        if (this.filterData.name) params.name = this.filterData.name
        if (this.filterData.status) params.status = this.filterData.status
        if (this.filterData.program_study) params.program_study = this.filterData.program_study
        if (this.filterData.season) params.season = this.filterData.season
        if (this.filterData.period) params.period = this.filterData.period
        request(API_DASH_FORM_LIST, params).then(res => {
          if (res.success) {
            this.referenceList = res.list
            this.referenceList && this.referenceList.map(itm => {
              itm.program_study =  text[`program.studi.${itm.program_study}.title`]
            })
            this.pageCount = res.pagination.total_page
            this.loading = false
          } else {
            this.alertMsgTable = get(res, 'resultInfo.resultMsg', '')
            this.isShowAlertTable = true
            this.$emit('handleAlertTables', this.isShowAlertTable, this.alertMsgTable)
          }
          loadscreen.hide()
        })
      },
      changeListPage () {
        this.getDataFromApi()
      },
      openDetail (item) {
        this.$router.push({
          name: DETAIL,
          query: {
            formId: item.id,
          }
        }).catch(() => {})
      },
      downloadData () {
        const linkData = []
        this.selected.map(itm => {
          const item = {}
          if (itm.pd_dikti_url && itm.name) {
            item.name = `${itm.name}_${itm.program_study}`
            item.link = itm.pd_dikti_url
            linkData.push(item)
          }
        })

        const zip = new JSZip()
        let count = 0
        const zipFilename = "Report.zip"
        linkData.forEach((itm) => {
          // loading a file and add it in a zip file
          JSZipUtils.getBinaryContent(itm.link,(err, data) => {
            if(err) {
              throw err; // or handle the error
            }
            zip.file(itm.name.replace(/ /g,"_")+ ".xls", data, {binary:true})
            count++
            if (count == linkData.length) {
              zip.generateAsync({type:'blob'}).then((content) =>  {
                saveAs(content, zipFilename)
                this.selected = []
                this.$refs.dialogInfoSuccessDonwload && this.$refs.dialogInfoSuccessDonwload.show()
              })
            }
          })
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
.form-tables {

}
</style>